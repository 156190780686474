<template>
  <form action="#" class="form">
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__topage.png" alt="" />
        </div>
        <div class="field__title">Choose file</div>
      </div>
      <select class="tt-uppercase" v-model="file" @change="changeFile($event)">
        <option v-for="(file, index) in files" :key="index" :value="file.value">
          {{ file.name }}
        </option>
      </select>
    </div>
    <!-- END  field -->

    <div class="field field_grp field_mb" ref="refType" v-show="types">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__flag.png" alt="" />
        </div>
        <div class="field__title">Choose type</div>
      </div>
      <select class="tt-uppercase" v-model="type" @change="changeType($event)">
        <option v-for="(type, index) in types" :key="index" :value="type.value">
          {{ type.name }}
        </option>
      </select>
    </div>
    <!-- END  field -->

    <div class="field field_grp field_mb" v-show="sources" ref="refSource">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__download.png" alt="" />
        </div>
        <div class="field__title">Source</div>
      </div>
      <select
        class="tt-uppercase"
        v-model="source"
        @change="changeSource($event)"
        translate="no"
      >
        <option
          v-for="(source, index) in sources"
          :key="index"
          :value="source.value"
        >
          {{ source.name }}
        </option>
      </select>
    </div>
    <!-- END  field -->

    <div class="form__btn-box">
      <a
        :href="getSource.value || getSource"
        class="button"
        target="_blank"
        data-source-btn
      >
        <span class="button__content">Download</span>
      </a>
    </div>

    <div class="bhelp bhelp_mt_files">
      <div class="bhelp__item">
        <div class="bhelp__ico">
          <img src="@/assets/images/icons/im__youtube.png" alt="" />
        </div>
        <a
          href="https://www.youtube.com/watch?v=NjxoGDG0zXk"
          target="_blank"
          data-fancybox
          class="bhelp__link"
          >Video instruction for installing files</a
        >
      </div>
      <div class="bhelp__item">
        <div class="bhelp__ico">
          <img src="@/assets/images/icons/im__msg.png" alt="" />
        </div>
        <router-link
          :to="{ name: 'Support' }"
          class="bhelp__link"
          data-inner-select="support"
          >Technical support</router-link
        >
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "Downloads",
  data() {
    return {
      classed: false,
    };
  },
  computed: {
    files: {
      get() {
        return this.$store.getters.getDownloads;
      },
      set(value) {
        this.$store.dispatch("updateDownloads", value);
      },
    },
    file: {
      get() {
        return this.$store.getters.getFile;
      },
      set(value) {
        this.$store.dispatch("updateFile", value);
      },
    },
    type: {
      get() {
        return this.$store.getters.getType;
      },
      set(value) {
        this.$store.dispatch("updateType", value);
      },
    },
    source: {
      get() {
        return this.$store.getters.getSource;
      },
      set(value) {
        this.$store.dispatch("updateSource", value);
      },
    },
    fileIndex: {
      get() {
        return this.$store.getters.getFileIndex;
      },
      set(value) {
        this.$store.dispatch("updateFileIndex", value);
      },
    },
    typeIndex: {
      get() {
        return this.$store.getters.getTypeIndex;
      },
      set(value) {
        this.$store.dispatch("updateTypeIndex", value);
      },
    },
    sourceIndex: {
      get() {
        return this.$store.getters.getSourceIndex;
      },
      set(value) {
        this.$store.dispatch("updateSourceIndex", value);
      },
    },
    types() {
      try {
        if (this.files[this.fileIndex].types) {
          return this.files[this.fileIndex].types;
        }
      } catch (e) {
        console.error(e.message);
      }
      return null;
    },
    sources() {
      try {
        if (this.files[this.fileIndex].sources) {
          return this.files[this.fileIndex].sources;
        } else {
          if (this.files[this.fileIndex].types)
            return this.files[this.fileIndex].types[this.typeIndex].sources;
        }
      } catch (e) {
        console.error(e.message);
      }
      return null;
    },
    getSource() {
      try {
        if (this.files[this.fileIndex].src) {
          return this.files[this.fileIndex].src;
        }
        if (this.files[this.fileIndex].sources) {
          return this.files[this.fileIndex].sources[this.sourceIndex];
        }
        if (this.files[this.fileIndex].types) {
          return this.files[this.fileIndex].types[this.typeIndex].sources[
            this.sourceIndex
          ];
        }
      } catch (e) {
        console.error(e.message);
      }
      return null;
    },
  },
  methods: {
    updateType() {
      if (this.files[this.fileIndex].types) {
        this.$store.dispatch(
          "updateType",
          this.files[this.fileIndex].types[this.typeIndex].value
        );
        if (this.files[this.fileIndex].types[this.typeIndex].sources) {
          this.$store.dispatch(
            "updateSource",
            this.files[this.fileIndex].types[this.typeIndex].sources[
              this.sourceIndex
            ].value
          );
        }
      }
    },
    updateSource() {
      if (this.files[this.fileIndex].sources) {
        this.$store.dispatch(
          "updateSource",
          this.files[this.fileIndex].sources[this.sourceIndex].value
        );
      }
    },
    changeFile(e) {
      try {
        this.fileIndex = this.files.map((d) => d.value).indexOf(e.target.value);
        this.updateType();
        this.updateSource();
      } catch (e) {
        console.error(e.message);
      }
    },
    changeType(e) {
      try {
        this.typeIndex = this.files[this.fileIndex].types
          .map((t) => t.value)
          .indexOf(e.target.value);
        this.updateType();
        this.updateSource();
      } catch (e) {
        console.error(e.message);
      }
    },
    changeSource(e) {
      try {
        if (this.files[this.fileIndex].sources) {
          this.sourceIndex = this.files[this.fileIndex].sources
            .map((s) => s.value)
            .indexOf(e.target.value);
        } else {
          if (this.files[this.fileIndex].types) {
            this.sourceIndex = this.files[this.fileIndex].types[
              this.typeIndex
            ].sources
              .map((s) => s.value)
              .indexOf(e.target.value);
          }
        }
      } catch (e) {
        console.error(e.message);
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("initDownloads");
    this.$store.dispatch("setDefaultOptions");
  },
};
</script>

<style scoped></style>
